import styled from "styled-components";

import { TEXT_WHITE_PRIMARY } from "../../styles";

export const Container = styled.div`
  margin: 24px 0;
  padding: 48px 0 0 0;

  border-top: 2px solid ${TEXT_WHITE_PRIMARY};
  border-bottom: 2px solid ${TEXT_WHITE_PRIMARY};

  background-size: cover;
  background-position: center;

  box-shadow: inset 0 0 24px rgba(0, 0, 0, 0.5);

  text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: auto;
  margin: 0 24px 8px 24px;
`;

export const Title = styled.h3`
  flex: 1 1 auto;
  // margin: auto 24px 24px 24px;

  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
`;
export const OpeningTime = styled.span`
  margin-right: 12px;
  padding-top: 4px;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  margin: 12px 24px 12px 24px;

  font-family: "Lato", sans-serif;
  font-weight: 300;
`;

export const Genres = styled.p`
  display: flex;
  margin-right: 36px;

  > svg {
    fill: ${TEXT_WHITE_PRIMARY};
    width: 16px;
    height: 16px;
    margin-right: 8px;
    // display: none;
  }
`;

export const Dj = styled.p`
  // display: none;
  flex: 1 1 auto;
  display: flex;

  > svg {
    margin: -2px;
    fill: ${TEXT_WHITE_PRIMARY};
    width: 20px;
    height: 20px;
    margin-right: 8px;
    // display: none;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  height: 32px;

  background-color: rgba(0, 0, 0, 0.5);

  margin-top: 24px;
  border-top: 2px solid ${TEXT_WHITE_PRIMARY};
`;

export const RequestButton = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);

  color: ${TEXT_WHITE_PRIMARY};
  font-size: 0.875rem;
  text-transform: uppercase;
`;

export const FindButton = styled.div`
  flex: 1 1 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 32px;
  margin: 0 0 0 auto;
  background-color: ${TEXT_WHITE_PRIMARY};
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 16px 100%);

  font-size: 0.875rem;
  color: #000;
  text-transform: uppercase;
  text-shadow: none;
`;
