import React from "react";
import styled, { keyframes } from "styled-components";
import classNames from "classnames";
import PropTypes from "prop-types";

const bgKeyframes = keyframes`
  from {
    fill: #999;
  }
  to {
    fill: #fff;
  }
`;

const Group = styled.g`
  &.active {
    > path {
      animation: ${bgKeyframes} 1s infinite alternate;
    }
  }
  > path {
    stroke: #fff;
    stroke-width: 3;
  }

  > g {
    circle {
      fill: #fff;
    }
    > g > path {
      stroke: none;
      fill: #000;
    }
  }
`;

const Bar = ({
  path,
  active = true,
  onClick,
  beerIconX,
  beerIconY,
  drinkIconX,
  drinkIconY,
  shotIconX,
  shotIconY,
  champagneIconX,
  champagneIconY
}) => (
  <Group onClick={onClick} className={classNames({ active })}>
    <path onClick={onClick} d={path} />
    {drinkIconX != null ? (
      <g>
        <circle cx={drinkIconX} cy={drinkIconY} r="24" />
        <g
          transform={`translate(${drinkIconX - 15}, ${drinkIconY -
            13}) scale(1.3)`}
        >
          <path d="M21 5V3H3v2l8 9v5H6v2h12v-2h-5v-5l8-9zM7.43 7L5.66 5h12.69l-1.78 2H7.43z" />
        </g>
      </g>
    ) : null}
    {beerIconX != null ? (
      <g>
        <circle cx={beerIconX} cy={beerIconY} r="24" />
        <g
          transform={`translate(${beerIconX - 17}, ${beerIconY -
            20}) scale(0.075)`}
        >
          <path
            d="M396.86,189.696h-51.816v-8.471c16.876-12.499,27.84-32.548,27.84-55.113c0-33.43-24.055-61.349-55.764-67.356
	C307.903,24.725,276.851,0.001,240.165,0c-20.304,0.001-39.79,7.852-54.44,21.513c-5.231-1.368-10.64-2.072-16.077-2.072
	c-25.849,0-48.398,15.683-58.222,38.235c-1.34-0.079-2.687-0.118-4.037-0.118c-37.8,0-68.553,30.753-68.553,68.553
	c0,20.813,9.335,39.475,24.024,52.058v283.526c0,16.5,13.5,30,30,30h222.184c16.5,0,30-13.5,30-30v-44h51.816
	c30.878,0,56-25.122,56-56v-116C452.86,214.817,427.738,189.696,396.86,189.696z M304.331,156.665l-175.536,0v61.051
	c0,10.493-8.507,19-19,19c-10.493,0-19-8.507-19-19v-65.971c-8.393-5.452-13.959-14.902-13.959-25.634
	c0-16.847,13.706-30.553,30.553-30.553c3.792,0,7.503,0.694,11.032,2.062c5.636,2.185,11.976,1.559,17.075-1.689
	c5.099-3.248,8.348-8.728,8.751-14.759c0.889-13.307,12.046-23.731,25.401-23.731c4.356,0,8.485,1.06,12.27,3.149
	c8.375,4.622,18.88,2.297,24.523-5.427C214.409,44.256,226.701,38,240.165,38c22.277,0,40.586,17.408,41.682,39.631
	c0.251,5.1,2.545,9.885,6.365,13.274c3.819,3.39,8.842,5.104,13.936,4.744c0.884-0.062,1.578-0.09,2.183-0.09
	c16.847,0,30.553,13.706,30.553,30.553S321.178,156.665,304.331,156.665z M400.86,361.696c0,2.131-1.869,4-4,4h-51.816v-124h51.816
	c2.131,0,4,1.869,4,4V361.696z"
          />
        </g>
      </g>
    ) : null}

    {shotIconX != null ? (
      <g>
        <circle cx={shotIconX} cy={shotIconY} r="24" />
        <g
          transform={`translate(${shotIconX - 18}, ${shotIconY -
            15}) scale(0.07)`}
        >
          <path
            d="M484.828,415.657l23.982-325.125H250.184l36.949,325.125H484.828z M484.188,119.22l-12.316,48.147
			c-98.521-49.266-123.156,49.821-184.738,0.555l-12.316-48.702H484.188z"
          />
          <path
            d="M191.594,378.603c52.909-52.909,52.909-138.685,0-191.584l-17.729,17.729c45.47,51.685,45.977,110.772,0.306,156.443
			c-45.67,45.67-104.757,45.154-156.442-0.307L0,378.612C52.909,431.502,138.694,431.502,191.594,378.603z"
          />
          <path
            d="M156.758,343.767c12.507-12.508,19.918-25.006,23.763-37.15l-81.396-27.129l-9.974-3.328l3.328,9.974l27.129,81.396
			C131.762,363.685,144.25,356.274,156.758,343.767z"
          />
          <path
            d="M68.649,292.54l-42.515,42.515c2.726,2.726,5.795,5.547,9.065,8.349c15.271,13.129,36.089,25.809,59.469,27.186
			L73,305.593L68.649,292.54z"
          />
          <path
            d="M156.404,222.198c-2.812-3.271-5.623-6.34-8.358-9.065l-42.515,42.515l13.062,4.352l64.996,21.659
			C182.213,258.287,169.534,237.479,156.404,222.198z"
          />
        </g>
      </g>
    ) : null}
    {champagneIconX != null ? (
      <g>
        <circle cx={champagneIconX} cy={champagneIconY} r="24" />
        <g
          transform={`translate(${champagneIconX - 15}, ${champagneIconY -
            17}) scale(0.75)`}
        >
          <path
            d="M42.943,2.914c-0.61-0.601-1.594-0.591-2.19,0.021l-5.958,6.071c-0.602,0.61-0.591,1.592,0.02,2.191
			c0.301,0.297,0.694,0.444,1.086,0.444c0.401,0,0.803-0.156,1.105-0.464l5.959-6.071C43.564,4.496,43.557,3.515,42.943,2.914z"
          />
          <path
            d="M32.711,8.269c0.855,0,1.393-0.693,1.393-1.549V1.554c0-0.856-0.537-1.551-1.393-1.551s-1.393,0.694-1.393,1.551V6.72
			C31.318,7.575,31.855,8.269,32.711,8.269z"
          />
          <path
            d="M32.059,11.678c-0.228-0.227-0.527-0.359-0.847-0.359s-0.623,0.122-0.851,0.349l-9.118,9.103l-3.696,1.051
			c-4.77,1.357-9.114,3.908-12.624,7.41l-4.426,4.416C0.179,33.966,0,34.397,0,34.847s0.18,0.874,0.497,1.191l9.391,9.4
			c0.317,0.315,0.751,0.496,1.201,0.496c0.449,0,0.881-0.179,1.201-0.496l4.417-4.427c3.501-3.51,6.053-7.854,7.411-12.624
			l1.052-3.695l9.104-9.122c0.228-0.226,0.347-0.526,0.347-0.846c0-0.32-0.134-0.62-0.36-0.847L32.059,11.678z M17.029,38.325
			l-0.423,0.425c-0.468,0.47-1.093,0.334-1.563-0.138l-1.295-1.295c-2.411,1.801-4.614,1.967-5.854,0.726
			c-1.241-1.24-1.075-3.443,0.725-5.854l-1.295-1.295c-0.47-0.47-0.605-1.095-0.136-1.562l0.424-0.424
			c0.225-0.226,0.497-0.317,0.815-0.316c0.319,0,0.607,0.145,0.833,0.369l1.286,1.306c2.096-1.479,4.473-1.811,5.705-0.579
			c1.231,1.231,0.901,3.609-0.579,5.705l1.305,1.285c0.226,0.227,0.37,0.514,0.37,0.833S17.254,38.101,17.029,38.325z"
          />
          <path
            d="M44.388,11.643h-5.167c-0.855,0-1.549,0.77-1.549,1.626c0,0.855,0.693,1.624,1.549,1.624h5.167
			c0.856,0,1.55-0.769,1.55-1.624C45.939,12.412,45.244,11.643,44.388,11.643z"
          />
        </g>
      </g>
    ) : null}
  </Group>
);

Bar.propTypes = {
  path: PropTypes.string.isRequired,
  active: PropTypes.bool,
  beerIconX: PropTypes.number,
  beerIconY: PropTypes.number,
  drinkIconX: PropTypes.number,
  drinkIconY: PropTypes.number,
  shotIconX: PropTypes.number,
  shotIconY: PropTypes.number,
  champagneIconX: PropTypes.number,
  champagneIconY: PropTypes.number
};

export default Bar;
