import React from "react";
import * as Styles from "./Bar.styles";
import MenuView from "components/MenuView/MenuView";
import { locations } from "data/locations";
import { withRouter } from "react-router";

import { ReactComponent as NkmLogo } from "assets/svgs/logos/nkmLogoWhite.svg";

class Bar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      locations: null
    };
  }
  render() {
    return (
      <MenuView>
        <Styles.Container>
          {this.state.product != null ? (
            <Styles.ModalContainer
              onClick={() => this.setState({ product: null, locations: null })}
            >
              <Styles.Modal>
                <h5>Bars serving: </h5>
                <h3>{this.state.product}</h3>
                {this.state.locations.map(bar => (
                  <Styles.BarListing>
                    <div>
                      <Styles.BarTitle>{bar.title}</Styles.BarTitle>
                      <Styles.BarFloor>On floor {bar.floor}</Styles.BarFloor>
                    </div>
                    <Styles.BarFind
                      onClick={() => {
                        this.setState(
                          { product: null, locations: null },
                          () => {
                            this.props.history.push("/map/" + bar.id);
                          }
                        );
                      }}
                    >
                      Find
                    </Styles.BarFind>
                  </Styles.BarListing>
                ))}
              </Styles.Modal>
            </Styles.ModalContainer>
          ) : null}
          <h1>Barmenu</h1>
          <h2>Something awesome beverages</h2>
          <Styles.HR />
          <h3>Beers &amp; Ciders</h3>
          <Styles.ProductList>
            <Styles.Product>
              <Styles.ProductName>Beer</Styles.ProductName>
              <Styles.ProductPrice>fr. 34:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>Cider</Styles.ProductName>
              <Styles.ProductPrice>fr. 39:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>Xide</Styles.ProductName>
              <Styles.ProductPrice>44:-</Styles.ProductPrice>
            </Styles.Product>
          </Styles.ProductList>
          <Styles.GuidanceContainer>
            <Styles.GuidanceText>Served on floor 2</Styles.GuidanceText>
            <Styles.FindButton
              onClick={() => this.openFinder("beers-and-ciders")}
            >
              Find on map
            </Styles.FindButton>
          </Styles.GuidanceContainer>
          <Styles.HR />
          <h3>Drinks</h3>
          <Styles.ProductList>
            <Styles.Product>
              <Styles.ProductName>Regular Drinks</Styles.ProductName>
              <Styles.ProductPrice>fr. 54:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>Red Bull Vodka</Styles.ProductName>
              <Styles.ProductPrice>69:-</Styles.ProductPrice>
            </Styles.Product>
          </Styles.ProductList>
          <Styles.GuidanceContainer>
            <Styles.GuidanceText>Served on floor 2</Styles.GuidanceText>
            <Styles.FindButton onClick={() => this.openFinder("drinks")}>
              Find on map
            </Styles.FindButton>
          </Styles.GuidanceContainer>
          <Styles.HR />
          <h3>Shots</h3>
          <Styles.ProductList>
            <Styles.Product>
              <Styles.ProductName>Sour Shots</Styles.ProductName>
              <Styles.ProductPrice>34:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>Regular Shots</Styles.ProductName>
              <Styles.ProductPrice>44:-</Styles.ProductPrice>
            </Styles.Product>
          </Styles.ProductList>
          <Styles.GuidanceContainer>
            <Styles.GuidanceText>Served on floor 2</Styles.GuidanceText>
            <Styles.FindButton onClick={() => this.openFinder("shots")}>
              Find on map
            </Styles.FindButton>
          </Styles.GuidanceContainer>
          <Styles.HR />
          <h3>Wine &amp; Special Assortments</h3>
          <Styles.ProductList>
            <Styles.Product>
              <Styles.ProductName>Red Wine</Styles.ProductName>
              <Styles.ProductPrice>44:- / 170:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>White Wine</Styles.ProductName>
              <Styles.ProductPrice>44:- / 170:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>Prosecco</Styles.ProductName>
              <Styles.ProductPrice>44:- / 200:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>Champagne</Styles.ProductName>
              <Styles.ProductPrice>600:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>Solstice Sour Beer</Styles.ProductName>
              <Styles.ProductPrice>79:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>Wicked Weed Sour Beer</Styles.ProductName>
              <Styles.ProductPrice>140:-</Styles.ProductPrice>
            </Styles.Product>
          </Styles.ProductList>
          <Styles.GuidanceContainer>
            <Styles.GuidanceText>Served on floor 3</Styles.GuidanceText>
            <Styles.FindButton
              onClick={() => this.openFinder("wine-and-special")}
            >
              Find on map
            </Styles.FindButton>
          </Styles.GuidanceContainer>
          <Styles.HR />
          <h3>Non-Alcoholic </h3>
          <Styles.ProductList>
            <Styles.Product>
              <Styles.ProductName>Beer</Styles.ProductName>
              <Styles.ProductPrice>20:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>Cider</Styles.ProductName>
              <Styles.ProductPrice>20:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>Drinks</Styles.ProductName>
              <Styles.ProductPrice>20:-</Styles.ProductPrice>
            </Styles.Product>
            <Styles.Product>
              <Styles.ProductName>Soda</Styles.ProductName>
              <Styles.ProductPrice>fr. 12:-</Styles.ProductPrice>
            </Styles.Product>
          </Styles.ProductList>

          <Styles.GuidanceContainer>
            <Styles.GuidanceText>Served on floor 2</Styles.GuidanceText>
            <Styles.FindButton onClick={() => this.openFinder("non-alcoholic")}>
              Find on map
            </Styles.FindButton>
          </Styles.GuidanceContainer>
          <Styles.NkmContainer>
            <Styles.HostedBy>Bar hosted by:</Styles.HostedBy>
            <NkmLogo />
            <Styles.NkmTitle>Nymbles Klubbmästeri</Styles.NkmTitle>
          </Styles.NkmContainer>
        </Styles.Container>
      </MenuView>
    );
  }

  openFinder(serving) {
    let locationIds = [];
    let productTitle = "";
    switch (serving) {
      case "beers-and-ciders":
        locationIds = [
          "bar-puben",
          "bar-lilla-hyllan",
          "bar-stora-hyllan",
          "bar-groten"
        ];
        productTitle = "Beers & Ciders";
        break;
      case "drinks":
        locationIds = ["bar-puben", "bar-lilla-hyllan"];
        productTitle = "Drinks";
        break;
      case "shots":
        locationIds = ["bar-shotbaren"];
        productTitle = "Shots";
        break;
      case "wine-and-special":
        locationIds = ["bar-champagnebaren"];
        productTitle = "Wine & Special Assortments";
        break;
      case "non-alcoholic":
        locationIds = ["bar-puben"];
        productTitle = "Non-Alcoholic Beverages";
        break;

      default:
        return null;
    }

    this.setState({
      product: productTitle,
      locations: locations.filter(l => locationIds.includes(l.id))
    });
  }
}

export default withRouter(Bar);
