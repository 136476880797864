import React from "react";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

import * as Styles from "./Map.styles";
import MenuView from "components/MenuView/MenuView";
import Hyllan from "./components/Hyllan/Hyllan";
import Groten from "./components/Groten/Groten";
import GamlaMatsalen from "./components/GamlaMatsalen/GamlaMatsalen";
import Floor2 from "./components/Floor2/Floor2";
import Bar from "./components/Bar/Bar";
import BarCard from "components/Bar/Bar";
import Restroom from "./components/Restroom/Restroom";
import SmokingArea from "./components/SmokingArea/SmokingArea";
import Stairs from "./components/Stairs/Stairs";
import Entrance from "./components/Entrance/Entrance";
import NyaMatsalen from "./components/NyaMatsalen/NyaMatsalen";

import { locations } from "data/locations";
import Dancefloor from "components/Dancefloor/Dancefloor";
import Floor3 from "./components/Floor3/Floor3";
import Floor1 from "./components/Floor1/Floor1";
import Wardrobe from "./components/Wardrobe/Wardrobe";
// import Tidningsrummet from "./components/Tidningsrummet/Tidningsrummet";

class Map extends React.Component {
  svgRef;
  history;

  constructor(props) {
    super(props);

    this.svgRef = React.createRef();

    this.onUpdate = this.onUpdate.bind(this);
    this.setFloor = this.setFloor.bind(this);

    this.state = {
      selected: null,
      floor: 2,
      showHeader: true
    };
  }

  onUpdate = ({ x, y, scale }) => {
    console.log(scale);
    if (scale < 1.05 && !this.state.showHeader) {
      this.setState({ showHeader: true });
    } else if (scale >= 1.05 && this.state.showHeader) {
      this.setState({ showHeader: false });
    }
    const { current: svg } = this.svgRef;
    const value = make3dTransformValue({ x, y, scale });
    svg.style.setProperty("transform", value);
  };

  static getDerivedStateFromProps(props, state) {
    const selected =
      props.match.params.uniqueId != null && props.match.params.uniqueId !== ""
        ? props.match.params.uniqueId
        : null;

    if (selected != null) {
      const location = locations.find(l => l.id === selected);

      if (state.floor !== location.floor) {
        return { floor: location.floor };
      }
    }

    return null;
  }

  render() {
    const selected =
      this.props.match.params.uniqueId != null &&
      this.props.match.params.uniqueId !== ""
        ? this.props.match.params.uniqueId
        : null;

    return (
      <MenuView>
        <Styles.Container>
          <Styles.Heading
            className={classNames({ hide: !this.state.showHeader })}
          >
            <h2>Map of</h2>
            <h1>Nymble</h1>
          </Styles.Heading>
          <Styles.ZoomContainer>
            <QuickPinchZoom
              onUpdate={this.onUpdate}
              className="zoom"
              onZoomStart={this.setInteractedWithMap}
            >
              <svg
                viewBox="400 100 1800 2400 "
                ref={this.svgRef}
                onClick={this.setSelected(null)}
              >
                <Entrance transparent={this.state.floor !== 1}>
                  <SmokingArea x={1515} y={880} />
                </Entrance>
                <Floor1 transparent={this.state.floor !== 1}>
                  <Restroom x={765} y={740} />
                  <Restroom x={681} y={1170} />
                  <Restroom x={1450} y={1900} />
                  <Wardrobe x={1235} y={1850} />
                  <Wardrobe x={1105} y={740} />
                  <Stairs x={991} y={485} />
                  <Stairs x={1415} y={2080} />
                </Floor1>

                <Floor2 transparent={this.state.floor !== 2}>
                  <GamlaMatsalen
                    onClick={this.setSelected("gamla-matsalen")}
                    active={selected === "gamla-matsalen"}
                  />
                  <NyaMatsalen
                    onClick={this.setSelected("nya-matsalen")}
                    active={selected === "nya-matsalen"}
                  />
                  <Hyllan
                    onClick={this.setSelected("hyllan")}
                    active={selected === "hyllan"}
                  />
                  <Groten
                    onClick={this.setSelected("groten")}
                    active={selected === "groten"}
                  />
                  <GamlaMatsalen
                    onClick={this.setSelected("gamla-matsalen")}
                    active={selected === "gamla-matsalen"}
                  />
                  <Bar
                    onClick={this.setSelected("bar-lilla-hyllan")}
                    active={selected === "bar-lilla-hyllan"}
                    path="M1232 588 L1232 661 L1112 687 L1112 611"
                    disabled={false}
                    drinkIconX={1143}
                    drinkIconY={642}
                    beerIconX={1198}
                    beerIconY={630}
                  />
                  <Bar
                    onClick={this.setSelected("bar-stora-hyllan")}
                    active={selected === "bar-stora-hyllan"}
                    path="M1397 900 
                    L1227 900 L1227 752
                    L1397 752"
                    disabled={false}
                    beerIconX={1225 + (1400 - 1225) / 2}
                    beerIconY={750 + (900 - 750) / 2}
                  />
                  <Bar
                    onClick={this.setSelected("bar-puben")}
                    active={selected === "bar-puben"}
                    path="M1226 1408
                  L1205 1510 L1379 1549 L1400 1445"
                    disabled={false}
                    shotIconX={1270}
                    shotIconY={1470}
                    beerIconX={1330}
                    beerIconY={1482}
                  />
                  <Bar
                    onClick={this.setSelected("bar-puben")}
                    active={selected === "bar-puben"}
                    path="M1226 1408
                  L1205 1510 L1379 1549 L1400 1445"
                    disabled={false}
                    drinkIconX={1270}
                    drinkIconY={1470}
                    beerIconX={1330}
                    beerIconY={1482}
                  />

                  <Bar
                    onClick={this.setSelected("bar-groten")}
                    active={selected === "bar-groten"}
                    path="M1547 428 L1515 275
                      L1400 299 l37 154z"
                    beerIconX={1475}
                    beerIconY={365}
                  />
                  <Bar
                    onClick={this.setSelected("bar-shotbaren")}
                    active={selected === "bar-shotbaren"}
                    path="M1093 1750 L1060 1832 L1220 1893 l32 -82z"
                    disabled={false}
                    shotIconX={1155}
                    shotIconY={1820}
                  />
                  <Restroom
                    path="M1488 1652 L1520 1478 L1663 1508 L1644 1561
                    L1590 1552 L1544 1675"
                    uniqueId="restroomStairs"
                    disabled={false}
                    x={1526}
                    y={1600}
                  />
                  <Stairs x={991} y={485} />
                  <Stairs x={1600} y={1620} />
                  <Stairs x={1458} y={1970} />
                </Floor2>
                <Floor3 transparent={this.state.floor !== 3}>
                  {/* <Tidningsrummet
                    onClick={this.setSelected("tidningsrummet")}
                    active={selected === "tidningsrummet"}
                  /> */}
                  <Restroom x={1130} y={562} />
                  <Stairs x={991} y={485} />
                  <Stairs x={1570} y={1590} />
                  {/* <path
          d={`"M1154 1390 L1103 1643 
      L1494 1796 L1542 1670 l-60 -25
      L1515 1464"}*/}
                  <Bar
                    onClick={this.setSelected("bar-champagnebaren")}
                    active={selected === "bar-champagnebaren"}
                    path="M1250 733 L1250 584 L1397 553 L1397 707z"
                    champagneIconX={1325}
                    champagneIconY={647}
                  />
                </Floor3>
              </svg>
            </QuickPinchZoom>
          </Styles.ZoomContainer>
          <Styles.FloorPicker>
            <div
              onClick={() => this.setFloor(1)}
              className={classNames({ active: this.state.floor === 1 })}
            >
              1
            </div>
            <div
              onClick={() => this.setFloor(2)}
              className={classNames({ active: this.state.floor === 2 })}
            >
              2
            </div>
            <div
              onClick={() => this.setFloor(3)}
              className={classNames({ active: this.state.floor === 3 })}
            >
              3
            </div>
            <span>Floor</span>
          </Styles.FloorPicker>
        </Styles.Container>
        {selected != null
          ? (() => {
              const location = locations.find(l => l.id === selected);
              if (location == null) {
                return null;
              }
              switch (location.type) {
                case "dancefloor":
                  return (
                    <Styles.BottomContainer>
                      <Dancefloor dancefloor={location} />
                    </Styles.BottomContainer>
                  );
                case "bar":
                  return (
                    <Styles.BottomContainer>
                      <BarCard bar={location} />
                    </Styles.BottomContainer>
                  );
                default:
                  return null;
              }
            })()
          : null}
      </MenuView>
    );
  }

  setSelected(uniqueId) {
    return event => {
      event.stopPropagation();
      this.props.history.push("/map/" + (uniqueId || ""));
    };
  }

  setFloor(floorNumber) {
    this.props.history.replace("/map");
    this.setState({ floor: floorNumber });
  }
}

export default withRouter(Map);
