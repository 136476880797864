import React from "react";
import PropTypes from "prop-types";

import * as Styles from "./Bar.styles";
import { ReactComponent as BarIcon } from "assets/svgs/icons/bar.svg";

export const Bar = ({ bar, key }) => (
  <Styles.Container
    key={key}
    className={"bar" + bar.id}
    style={{ backgroundImage: `url(${bar.imageUrl})` }}
  >
    <Styles.Header>
      <Styles.Title>
        {bar.title.split(" ").map(t => (
          <span>
            {t}
            <br />
          </span>
        ))}
      </Styles.Title>
      <Styles.OpeningTime></Styles.OpeningTime>
    </Styles.Header>
    <Styles.Content>
      <Styles.Genres>
        <BarIcon />
        <span>{bar.serving}</span>
      </Styles.Genres>
    </Styles.Content>
    <Styles.ButtonRow></Styles.ButtonRow>
  </Styles.Container>
);

Bar.propTypes = {
  bar: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    serving: PropTypes.string,
    imageUrl: PropTypes.string,
    openingTime: PropTypes.number
  })
};

export default Bar;
