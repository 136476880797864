import styled from "styled-components";

import { TEXT_WHITE_PRIMARY } from "../../styles";

export const Container = styled.div`
  margin: 24px 0;
  padding: 48px 0 0 0;

  border-top: 2px solid ${TEXT_WHITE_PRIMARY};
  border-bottom: 2px solid ${TEXT_WHITE_PRIMARY};
  background-size: cover;
  background-position: center;
  background-color: #000;

  box-shadow: inset 0 0 24px rgba(0, 0, 0, 0.5);

  text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: calc(100vh - 56px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div`
  width: calc(100% - 96px);
  margin: 0 24px;
  max-width: 400px;
  padding: 48px 24px;
  margin-bottom: 96px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.9);
  text-shadow: none;

  > h3 {
    margin-bottom: 6px;
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  > h5 {
    font-size: 0.875rem;
    font-weight: 300;
    margin-bottom: 6px;
  }
`;

export const SetListContainer = styled.div`
  max-height: 300px;
  min-height: 200px;
  overflow-y: auto;
`;

export const SetContainer = styled.div`
  display: flex;
  height: 36px;
  align-items: center;
`;

export const SetTime = styled.div`
  margin-right: 12px;

  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
`;
export const SetDj = styled.div`
  line-height: 140%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: auto;
  margin: 0 24px 8px 24px;
`;

export const Title = styled.h3`
  flex: 1 1 auto;
  // margin: auto 24px 24px 24px;

  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
`;
export const OpeningTime = styled.span`
  margin-right: 12px;
  padding-top: 4px;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  margin: 12px 24px 12px 24px;

  font-family: "Lato", sans-serif;
  font-weight: 300;
`;

export const Genres = styled.p`
  display: flex;
  margin-right: 36px;

  > svg {
    fill: ${TEXT_WHITE_PRIMARY};
    width: 16px;
    height: 16px;
    margin-right: 8px;
    // display: none;
  }
`;

export const Dj = styled.p`
  // display: none;
  flex: 1 1 auto;
  display: flex;

  > svg {
    margin: -2px;
    fill: ${TEXT_WHITE_PRIMARY};
    width: 20px;
    height: 20px;
    margin-right: 8px;
    // display: none;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  height: 32px;

  margin-top: 24px;
  border-top: 2px solid ${TEXT_WHITE_PRIMARY};
`;

export const RequestButton = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);

  color: ${TEXT_WHITE_PRIMARY};
  font-size: 0.875rem;
  text-transform: uppercase;
`;

export const SetTimesButton = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);

  color: ${TEXT_WHITE_PRIMARY};
  font-size: 0.875rem;
  text-transform: uppercase;
`;

export const FindButton = styled.div`
  flex: 0 1 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 32px;
  margin: 0 0 0 auto;
  background-color: ${TEXT_WHITE_PRIMARY};
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 16px 100%);

  font-size: 0.875rem;
  color: #000;
  text-transform: uppercase;
  text-shadow: none;
`;
