import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import PropTypes from "prop-types";

const Group = styled.g`
  &.disabled {
    opacity: 0.5;
  }
  > pattern > path {
    stroke-width: 18;
    stroke-linecap: square;
    stroke: rgba(255, 0, 0, 0.9);
  }

  > path {
    fill: #f00;
  }
  > circle {
    fill: #fff;
  }
  > text {
    font-size: 1.25rem;
    font-family: "Lato", sans-serif;
    font-weight: 300;
  }

  > g {
    fill: #000;
  }
`;

const Wardrobe = ({ path, uniqueId, disabled, onClick, x, y }) => (
  <Group className={classNames({ disabled })}>
    <circle cx={x} cy={y} r="24"></circle>
    <g transform={`translate(${x - 16}, ${y - 20}) scale(0.08) `}>
      <path d="m421.47,297.344c-0.001,0-0.001,0 0,0l-159.503-99.684c-2.781-1.738-5.995-2.66-9.274-2.66h-13.693v-7.438c27.081-7.646 46.986-32.58 46.986-62.075 0-35.558-28.928-64.487-64.486-64.487-35.559,0-64.487,28.929-64.487,64.487 0,9.665 7.835,17.5 17.5,17.5s17.5-7.835 17.5-17.5c0-16.259 13.227-29.487 29.487-29.487 16.259,0 29.486,13.228 29.486,29.487 0,16.259-13.228,29.487-29.486,29.487-9.665,0-17.5,7.835-17.5,17.5v22.526h-13.693c-3.279,0-6.493,0.922-9.274,2.66l-159.503,99.684c-13.481,8.427-21.53,22.948-21.53,38.847 0,25.259 20.55,45.809 45.809,45.809h351.381c25.26,0 45.81-20.55 45.81-45.809 0-15.899-8.049-30.42-21.53-38.847zm-24.28,49.656h-351.381c-5.96,0-10.809-4.849-10.809-10.809 0-3.751 1.899-7.179 5.08-9.167l155.245-97.024h52.349l155.245,97.024c3.182,1.988 5.081,5.416 5.081,9.167 0,5.96-4.85,10.809-10.81,10.809z" />
    </g>
  </Group>
);

Wardrobe.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
};

export default Wardrobe;
