import React from "react";
import * as Styles from "./Music.styles";
import MenuView from "components/MenuView/MenuView";
import Dancefloor from "../../components/Dancefloor/Dancefloor";

import { ReactComponent as PlatoonLogo } from "assets/svgs/logos/platoonLogoWhite.svg";
import { ReactComponent as RnLogo } from "assets/svgs/logos/rnLogoWhite.svg";

import { locations } from "data/locations";

export class Music extends React.Component {
  render() {
    return (
      <MenuView>
        <Styles.Container>
          <h1>Dancefloors</h1>
          <h2>Four dancefloors, 4 vibes</h2>
          <Styles.Intro>
            Dance to the beat, groove to the mood, sing with your friends.
            Whatever makes your foot loose, we will make you dance with
            somebody. Become a dancing queen on one of our wide range of
            dancefloors&hellip;
          </Styles.Intro>
          {locations
            .filter(l => l.type === "dancefloor")
            .map(dancefloor => (
              <Dancefloor
                setTimes={
                  this.props.setTimes != null
                    ? this.props.setTimes[dancefloor.id]
                    : null
                }
                key={dancefloor.id}
                dancefloor={dancefloor}
              />
            ))}
          <Styles.AttributionTitle>
            Music and lights provided by:
          </Styles.AttributionTitle>
          <Styles.AttributionContainer>
            <PlatoonLogo style={{ width: 96 }} />
            <RnLogo style={{ width: 128 }} />
          </Styles.AttributionContainer>
        </Styles.Container>
      </MenuView>
    );
  }
}
