import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./scenes/Home/Home";
import Map from "scenes/Map/Map";
import { Music } from "scenes/Music/Music";
import Bar from "scenes/Bar/Bar";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      setTimes: null
    };
  }

  componentDidMount() {
    this.fetchSetTimes();
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/map/:uniqueId?">
            <Map setTimes={this.setTimes} />
          </Route>
          <Route path="/music">
            <Music setTimes={this.state.setTimes} />
          </Route>
          <Route path="/bar" component={Bar} />
        </Switch>
      </Router>
    );
  }

  fetchSetTimes() {
    fetch("/set-times.json")
      .then(response => response.json())
      .then(setTimes => this.setState({ setTimes }))
      .catch(e => console.log(e));
  }
}
