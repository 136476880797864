import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-bottom: 96px;

  > h1 {
    margin: 48px 24px 8px 24px;

    font-size: 2.5rem;
    line-height: 140%;
    font-weight: 700;
    text-transform: uppercase;
  }

  > h2 {
    margin: auto 24px;

    font-family: "Lato", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 140%;
  }
`;

export const Intro = styled.p`
  margin: 24px 24px 48px 24px;
  line-height: 150%;
  text-align: justify;
`;

export const AttributionTitle = styled.p`
  padding-top: 24px;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
`;

export const AttributionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  > svg {
    margin: 0 24px;
    fill: rgba(255, 255, 255, 0.9);
  }
`;
