import React from "react";
import styled from "styled-components";

const Group = styled.g`
  #roadTextPath {
    display: none;
  }
`;

export const TransparentGroup = styled.g`
  opacity: 0.5;
  > path {
    stroke: #fff;
    stroke-width: 8;
    fill: none;
  }
  > g {
    > text {
      fill: #fff;
      stroke-width: 3;
      font-family: "Oswald", sans-serif;
      font-weight: 600;
      font-size: 3rem;
      text-transform: uppercase;
      letter-spacing: 100;

      > textPath {
        stroke: #fff;
        fill: #fff;
        font-size: 6rem;
      }
    }
  }
`;

export const NonTransparentGroup = styled.g`
  transition: opacity 1s ease-in-out;
`;

export const Entrance = ({ transparent, children }) => (
  <Group>
    <TransparentGroup>
      <path
        d="M1400 680 L1550 680 
        Q1640 700, 1815 1080
        Q1830 1300, 1750 2400"
      />
      <path
        d="M1750 100 Q1810 200, 1817 643 L1712 480 
      Q1650 300, 1650 100"
      />
      <g transform="translate(1620, 600) rotate(52)">
        <text x="0" y="0" textLength="180">
          Entrance
        </text>
      </g>
      <path id="roadTextPath" d="M1925 500 Q1950 900, 1870 2400" />
      <g>
        <text x="0" y="0" textLength="1400">
          <textPath xlinkHref="#roadTextPath">Drottning Kristinas Väg</textPath>
        </text>
      </g>

      <path d="M1630 743 l0 300 L1400 1043" />
    </TransparentGroup>
    <NonTransparentGroup style={{ opacity: transparent ? 0.5 : 1 }}>
      {children}
    </NonTransparentGroup>
  </Group>
);

export default Entrance;
