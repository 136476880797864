import React from "react"
import styled, { keyframes } from "styled-components"
import classNames from "classnames"
import PropTypes from "prop-types"

const bgKeyframes = keyframes`
  from {
    fill: #999;
  }
  to {
    fill: #fff;
  }
`
const Group = styled.g`
  > path.active {
    fill: none;
    &.active {
      animation: ${bgKeyframes} 1s infinite alternate;
    }
  }
  > g {
    > text {
      fill: #fff;
      font-family: "Oswald", sans-serif;
      font-weight: 600;
      font-size: 3rem;
      text-transform: uppercase;
      letter-spacing: 100;

      &.active {
        fill: #000;
      }
    }
  }
`

const NyaMatsalen = ({ onClick, active = false }) => (
  <Group onClick={onClick}>
    <path
      className={classNames({ active })}
      d="M1069 1370 L648 1285 
                    L648 793
                    L1069 711z"
    />
    <g>
      <text
        x={690}
        y={1030}
        textLength="110"
        className={classNames({ active })}
      >
        Nya
      </text>
      <text
        x={690}
        y={1110}
        textLength="280"
        className={classNames({ active })}
      >
        Matsalen
      </text>
    </g>
  </Group>
)

NyaMatsalen.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

export default NyaMatsalen
