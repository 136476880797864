import React from "react";
import PropTypes from "prop-types";

import * as Styles from "./Dancefloor.styles";
import { ReactComponent as MusicIcon } from "assets/svgs/icons/music.svg";
import { ReactComponent as DjIcon } from "assets/svgs/icons/dj.svg";
import { withRouter } from "react-router";

class Dancefloor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSetTimes: false
    };
  }
  render() {
    const now = Math.round(new Date().getTime() / 1000);
    let dj = null;
    if (this.props.setTimes != null) {
      dj = this.props.setTimes.find(s => s.start <= now && s.end >= now);
    }
    return (
      <Styles.Container
        style={{
          backgroundImage: `url(${this.props.dancefloor.imageUrl})`
        }}
        className={"dancefloor" + this.props.dancefloor.id}
      >
        {this.state.showSetTimes ? (
          <Styles.ModalContainer
            onClick={() => this.setState({ showSetTimes: false })}
          >
            <Styles.Modal>
              <h3>{this.props.dancefloor.title}</h3>
              <h5>Setlist</h5>
              <Styles.SetListContainer>
                {this.props.setTimes != null
                  ? this.props.setTimes
                      .filter(
                        s => s.end > Math.floor(new Date().getTime() / 1000)
                      )
                      .map(s => (
                        <Styles.SetContainer>
                          <Styles.SetTime>
                            {getSwedishTime(s.start)}
                          </Styles.SetTime>
                          <Styles.SetDj>{s.dj}</Styles.SetDj>
                        </Styles.SetContainer>
                      ))
                  : null}
              </Styles.SetListContainer>
            </Styles.Modal>
          </Styles.ModalContainer>
        ) : null}
        <Styles.Header>
          <Styles.Title>
            {this.props.dancefloor.title.split(" ").map((t, i) => (
              <span key={"Title" + i}>
                {t}
                <br />
              </span>
            ))}
          </Styles.Title>
          <Styles.OpeningTime>
            {this.props.dancefloor.opening <= now
              ? "Open Now"
              : `Opens ${getSwedishTime(this.props.dancefloor.opening)}`}
          </Styles.OpeningTime>
        </Styles.Header>
        <Styles.Content>
          <Styles.Genres>
            <MusicIcon />
            <span>{this.props.dancefloor.genres}</span>
          </Styles.Genres>
          {dj != null ? (
            <Styles.Dj>
              <DjIcon />
              <span>{dj.dj}</span>
            </Styles.Dj>
          ) : null}
        </Styles.Content>

        <Styles.ButtonRow>
          <Styles.SetTimesButton onClick={() => this.showSetTimes()}>
            Set Times
          </Styles.SetTimesButton>
          {this.props.dancefloor.takesRequests ? (
            <Styles.RequestButton>Request song</Styles.RequestButton>
          ) : null}
          {this.props.history.location.pathname.startsWith("/map") ? null : (
            <Styles.FindButton
              onClick={() =>
                this.props.history.push("/map/" + this.props.dancefloor.id)
              }
            >
              Find on map
            </Styles.FindButton>
          )}
        </Styles.ButtonRow>
      </Styles.Container>
    );
  }

  showSetTimes() {
    this.setState({ showSetTimes: true });
  }
}

const getSwedishTime = unixTime => {
  const seDate = new Date(unixTime * 1000 + 3600 * 1000);
  return pad(seDate.getUTCHours(), 2) + ":" + pad(seDate.getUTCMinutes(), 2);
};

const pad = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

Dancefloor.propTypes = {
  dancefloor: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    genres: PropTypes.string,
    dj: PropTypes.string
  })
};

export default withRouter(Dancefloor);
