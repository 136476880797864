import React from "react";

import { ReactComponent as TpLogo } from "../../assets/svgs/logos/tp3LogoWhite.svg";
import * as Styles from "./Home.styles";
import MenuView from "components/MenuView/MenuView";
// import CustomHelmet from "components/CustomHelmet/CustomHelmet";

const OPENING_TIME = 1584212400;

export class Home extends React.Component {
  clockTimer;
  constructor(props) {
    super(props);

    this.state = {
      timeLeft: this.getTimeLeft()
    };

    this.clockTimer = setInterval(
      () => this.setState({ timeLeft: this.getTimeLeft() }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.clockTimer);
  }
  render() {
    return (
      <MenuView>
        <Styles.Container>
          {/*<CustomHelmet
            title="Tentapub Nymble"
            description=""
            keywords=""
            canonical=""
          />
          jkwr */}
          <Styles.TopContainer>
            <TpLogo className="tpLogo" />
            <p>
              You've made it. Two weeks of of intense exams are finally over.
              Now comes a celebration like no other. Join us for the unique KTH
              Tentapub experience which occurs only four times a year. We bring
              this special event made for you to let go of all your emotions.
            </p>
            <p>
              Get ready for a night that you won't forget, won't regret but yet
              so deeply deserve.
            </p>
          </Styles.TopContainer>

          <Styles.BottomContainer>
            <Styles.CountdownContainer text={this.getTimeLeftFormatted()}>
              {this.getTimeLeftFormatted()}
            </Styles.CountdownContainer>
            <Styles.FacebookButton
              onClick={() =>
                window
                  .open(
                    "https://www.facebook.com/events/333765814227976/",
                    "_blank"
                  )
                  .focus()
              }
            >
              View Event on Facebook
            </Styles.FacebookButton>
            <Styles.PeopleCount>
              1.3k attending or interested
            </Styles.PeopleCount>
          </Styles.BottomContainer>
        </Styles.Container>
      </MenuView>
    );
  }

  getTimeLeftFormatted() {
    if (this.state.timeLeft <= 0) {
      return "NOW OPEN";
    }
    const daysLeft = Math.floor(
      this.state.timeLeft.getTime() / (24 * 60 * 60 * 1000)
    );
    const hoursLeft = Math.floor(
      (this.state.timeLeft.getTime() - daysLeft * 24 * 60 * 60 * 1000) /
        (60 * 60 * 1000)
    );
    const minutesLeft = Math.floor(
      (this.state.timeLeft.getTime() -
        daysLeft * 24 * 60 * 60 * 1000 -
        hoursLeft * 60 * 60 * 1000) /
        (60 * 1000)
    );
    const secondsLeft = Math.floor(
      (this.state.timeLeft.getTime() -
        daysLeft * 24 * 60 * 60 * 1000 -
        hoursLeft * 60 * 60 * 1000 -
        minutesLeft * 60 * 1000) /
        1000
    );

    return (
      this.pad(daysLeft, 2) +
      ":" +
      this.pad(hoursLeft, 2) +
      ":" +
      this.pad(minutesLeft, 2) +
      ":" +
      this.pad(secondsLeft, 2)
    );
  }

  pad(n, width, z) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  getTimeLeft() {
    const now = new Date();
    const startDate = new Date(OPENING_TIME * 1000);

    return new Date(startDate - now);
  }
}
