import styled from "styled-components";
import { TEXT_WHITE_PRIMARY, TEXT_WHITE_LIGHT } from "../../styles";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  background-color: #000;
`;

export const Heading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  padding: 48px 24px 8px 24px;

  transition: opacity 1s ease-in-out;

  &.hide {
    opacity: 0;
  }

  > h1 {
    display: block;
    font-size: 2.5rem;
    line-height: 140%;
    font-weight: 700;
    text-transform: uppercase;
  }

  > h2 {
    display: block;
    font-size: 1rem;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
  }
`;

export const ZoomContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  background-color: #000;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
  }
  > div > svg {
    width: 100%;
  }
  /* > div > svg {
    @media (min-aspect-ratio: 1400/2300) {
      width: 80%;
    }
    @media (max-aspect-ratio: 1400/2300) {
      width: 80%;
    }*/
  }
`;

export const CoordDisplay = styled.div`
  &.coord {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    color: #f00;
  }
`;

export const FloorPicker = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 24px;
  right: 0;

  color: ${TEXT_WHITE_LIGHT};
  text-shadow: 2px 2px rgba(0, 0, 0, 0.1);

  > span {
    font-size: 0.75rem;
    font-weight: 300;
    text-transform: uppercase;
    margin: 3px 12px;
  }
  > div {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;

    transition: font-size 0.1s ease-in-out;

    &.active {
      font-size: 2rem;
      color: ${TEXT_WHITE_PRIMARY};
    }
  }
`;
export const BottomContainer = styled.div`
  position: absolute;
  bottom: 48px;
  left: 0;
  width: 100%;
`;
