import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import PropTypes from "prop-types";

/*import wcUrl from "../../../../assets/svgs/icons/wc.svg"

const STRIPE_SIZE = 50
*/
const Group = styled.g`
  &.disabled {
    opacity: 0.5;
  }
  > pattern > path {
    stroke-width: 18;
    stroke-linecap: square;
    stroke: rgba(255, 0, 0, 0.9);
  }

  > path {
    fill: #f00;
  }
  > circle {
    fill: #fff;
  }
  > text {
    font-size: 1.25rem;
    font-family: "Lato", sans-serif;
    font-weight: 300;
  }

  > g {
    fill: #000;
  }
`;

const Restroom = ({ path, uniqueId, disabled, onClick, x, y }) => (
  <Group className={classNames({ disabled })}>
    <circle cx={x} cy={y} r="24"></circle>
    <g transform={`translate(${x - 16}, ${y - 17}) scale(0.08) `}>
      <path
        d="M106.667,85.333c23.573,0,42.667-19.093,42.667-42.667C149.333,19.093,130.24,0,106.667,0S64,19.093,64,42.667
				C64,66.24,83.093,85.333,106.667,85.333z"
      />
      <path
        d="M298.667,85.333c23.573,0,42.667-19.093,42.667-42.667C341.333,19.093,322.24,0,298.667,0
				C275.093,0,256,19.093,256,42.667C256,66.24,275.093,85.333,298.667,85.333z"
      />
      <path
        d="M138.667,106.667h-64C51.2,106.667,32,125.867,32,149.333v117.333h32v160h85.333v-160h32V149.333
				C181.333,125.867,162.133,106.667,138.667,106.667z"
      />
      <path
        d="M340.373,135.893c-5.76-17.493-22.08-29.227-40.427-29.227h-2.453c-18.347,0-34.667,11.733-40.427,29.227l-54.4,162.773
				h64v128h64v-128h64L340.373,135.893z"
      />
    </g>
  </Group>
);

Restroom.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
};

export default Restroom;
