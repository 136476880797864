import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 56px;

  background-color: #fff;

  color: rgba(0, 0, 0, 0.9);

  > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 56px;
    text-decoration: none;

    &.active {
      > svg {
        fill: rgba(0, 0, 0, 0.9);
      }
      > span {
        color: rgba(0, 0, 0, 0.9);
      }
    }

    > svg {
      width: 24px;
      height: 24px;
      padding-top: 4px;
      fill: rgba(0, 0, 0, 0.5);
    }

    > span {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.9);
      fill: rgba(0, 0, 0, 0.5);
    }
  }
`;
