import styled from "styled-components";
import { TEXT_WHITE_LIGHT } from "../../styles";

export const Container = styled.div`
  box-sizing: border-box;
  padding-bottom: 96px;

  > h1 {
    margin: 48px 24px 8px 24px;

    font-size: 2.5rem;
    line-height: 140%;
    font-weight: 700;
    text-transform: uppercase;
  }

  > h2 {
    margin: auto 24px;

    font-family: "Lato", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
  }

  > h3 {
    margin: auto 24px 24px 24px;

    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: calc(100vh - 56px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div`
  width: calc(100% - 96px);
  margin: 0 48px;
  max-width: 400px;
  padding: 48px;
  margin-bottom: 96px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.9);

  > h3 {
    margin-bottom: 24px;
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  > h5 {
    font-size: 0.875rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 6px;
  }
`;

export const BarListing = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
`;

export const BarTitle = styled.span`
  display: block;
  font-size: 1.125rem;
  margin-bottom: 6px;
`;

export const BarFloor = styled.span`
  display: block;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;
`;

export const BarFind = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
`;

export const HR = styled.div`
  width: auto;
  height: 4px;
  margin: 48px 24px 24px 24px;

  background-color: rgba(255, 255, 255, 0.9);
`;

export const ProductList = styled.ul`
  margin: 24px;
`;

export const Product = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const ProductName = styled.span`
  flex: 1 1 auto;

  font-size: 1rem;
`;

export const ProductPrice = styled.span`
  flex: 0 0 auto;
  text-align: right;
`;

export const GuidanceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GuidanceText = styled.p`
  margin: 0 24px;
  color: ${TEXT_WHITE_LIGHT};
  font-size: 0.875rem;
`;

export const FindButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 32px;
  margin: 0 0 0 auto;
  background-color: rgba(255, 255, 255, 0.9);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 16px 100%);

  font-size: 0.875rem;
  color: #000;
  text-transform: uppercase;
`;

export const NkmContainer = styled.div`
  margin-top: 48px;
  > svg {
    display: block;
    margin: 12px auto;
    width: 128px;
  }
`;

export const HostedBy = styled.p`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
`;

export const NkmTitle = styled.p`
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
`;
