import React from "react";
import styled from "styled-components";

const Group = styled.g`
  transition: opacity 1s ease-in-out;
  > .floor {
    display: none;
    fill: #000;
  }
  > .closed {
    // fill: #e9e9e9; // rgba(255, 255, 255, 0.);
    fill-rule: evenodd;
    stroke: #fff;
    stroke-width: 8;
    display: none;
  }

  > .outline {
    stroke: #fff;
    stroke-width: 8;
    fill: none;
  }

  > .stairs {
    opacity: 0.5;
    stroke: #fff;
    stroke-width: 3;
    fill: none;
  }
`;
export default class Floor2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      mounted: false
    };
  }

  static getDerivedStateFromProps(props, prevState) {
    if (!prevState.mounted) {
      return { mounted: true, display: !props.transparent };
    }
    return null;
  }

  render() {
    return (
      <Group
        style={{
          visibility: this.state.display ? "inherit" : "hidden",
          opacity: this.props.transparent ? 0 : 1
        }}
      >
        <path
          className="floor"
          d="M1400 550 L 1567 513 L1519 272 
            L913 394 L963 636 L645 700 L645 1288 L538 1625
            L1248 1911 L1320 1733 L1432 1778 L1397 1868
            L1446 1887 L1272 2324 L1341 2350 L1681 1500
            L1401 1442"
        />
        <path
          className="closed"
          d="M538 1625 L1248 1911
                L1105 2255 L1341 2350
                L1681 1500
                L1401 1442 L1400 550 
                L1567 513 L1519 272
                L1041 369 L913 394 L963 636 L645 700 L645 1288
                L538 1625
                M1208 1401 L1205 1414
                L1510 1475 L1478 1650
                L1488 1652 L1520 1478 L1663 1508 L1644 1561
                L1590 1552 L1544 1675
                L1551 1676 L1595 1561 L1639 1570 L1461 2022
                L1380 2228 L1337 2210 L1463 1890
                L1397 1868 
                L1432 1778 L1320 1733 L1251 1898
                L1028 1810 L1098 1631 L1025 1614
                L985 1797 L558 1617 L657 1304 L1072 1388
                L1038 1552 
                L1117 1570 L1154 1390 L660 1291 
                L660 787
                L975 725 L975 640 L925 400 L1028 383
                L1060 523 L1069 522 L1037 381 L1499 282 L1531 429
                L1214 500 L1227 570 L1072 603 L1072 725
                L1109 725 L1109 607 L1229 581 
                L1384 548 L1380 1440z"
        />
        <path
          d="M538 1625 L1248 1911
                L1105 2255 L1341 2350
                L1681 1500
                L1401 1442 L1400 550 
                L1567 513 L1519 272
                L1041 369 L913 394 L963 636 L645 700 L645 1288
                L538 1625"
          className="outline"
        />
        <line className="outline" x1={1400} y1="1442" x2="1225" y2="1405" />
        {/* Wall between nya and gamla */}
        {/* When gamla is not closed */}
        <path
          className="outline"
          d="M645 1288 L1154 1390 L1117 1570 L1037 1552 L1073 1373"
        />
        {/* Lower wall between puben and gamla when gamla not closed */}
        <path
          className="outline"
          d={`M1025 1820 L1100 1632 
    L1024 1613 L984 1805`}
        />
        {/* Doors to gamla */}
        {/*<path
          className="outline"
          d="M645 1288 L1154 1390 
        L1117 1570 L1080 1565 l-15 60
        L1100 1632 L1025 1820"
        /> */}
        {/* 37 180*/}
        <line className="outline" x1="1515" y1="1464" x2="1478" y2="1655" />
        {/* Gröten left wall */}
        <line className="outline" x1="1060" y1="523" x2="1025" y2="370" />
        {/* Stairs at gröten */}
        <g className="stairs">
          <path d="M1000 533 L982 443" />
          <path d="M1060 521 l-120 24" />
          <path d="M1058 511 l-120 24" />
          <path d="M1056 501 l-120 24" />
          <path d="M1054 491 l-120 24" />
          <path d="M1052 481 l-120 24" />
          <path d="M1050 471 l-120 24" />
          <path d="M1048 461 l-120 24" />
          <path d="M1046 451 l-120 24" />
          <path d="M1044 441 l-120 24" />
          <path d="M1042 431 l-120 24" />
        </g>
        <path
          className="outline"
          d="M1300 567 L1072 617 L1072 725 L1109 725 L1109 610"
        />
        {/*
        If no seperator at gröten
        <path
          className="outline"
          d={`M1550 425 L1215 500
      L1232 580 `}
        /> */}
        <path
          className="outline"
          d={`M1300 567 l-17 -80 L1215 500
      l17 80`}
        />
        <path
          className="outline"
          d={`M1550 430 L1345 474
      l17 84 L1400 550`}
        />

        <path className="outline" d={`M963 636 L963 728 L645 790`} />
        {/* Stairs to floor 3 */}
        <path className="outline" d={`M1542 1670 L1590 1542 l60 25`} />
        <g className="stairs">
          <path d="M1542 1670 l60 25" />
          <path d="M1546 1660 l60 25" />
          <path d="M1550 1650 l60 25" />
          <path d="M1554 1640 l60 25" />
          <path d="M1558 1630 l60 25" />
          <path d="M1562 1620 l60 25" />
          <path d="M1566 1610 l60 25" />
          <path d="M1570 1600 l60 25" />
        </g>
        {/* Stairs to ground floor */}
        <path
          className="outline"
          d={`M1248 1911 L1318 1728 L1433 1768 
        L1395 1865 L1456 1888 L1330 2208
        l60 25`}
        />
        <g className="stairs">
          <path d="M1444 1918 l60 25" />
          <path d="M1440 1928 l60 25" />
          <path d="M1436 1938 l60 25" />
          <path d="M1432 1948 l60 25" />
          <path d="M1428 1958 l60 25" />
          <path d="M1424 1968 l60 25" />
          <path d="M1420 1978 l60 25" />
          <path d="M1416 1988 l60 25" />
          <path d="M1412 1998 l60 25" />

          <path d="M1396 2038 l60 25" />
          <path d="M1392 2048 l60 25" />
          <path d="M1388 2058 l60 25" />
          <path d="M1384 2068 l60 25" />
          <path d="M1380 2078 l60 25" />
          <path d="M1376 2088 l60 25" />
          <path d="M1372 2098 l60 25" />
          <path d="M1368 21008 l60 25" />

          <path d="M1352 2148 l60 25" />
          <path d="M1348 2158 l60 25" />
          <path d="M1344 2168 l60 25" />
          <path d="M1340 2178 l60 25" />
          <path d="M1336 2188 l60 25" />
          <path d="M1332 2198 l60 25" />
        </g>

        <path d="" className="outline" />
        <path
          className="outline"
          d="M1072 822 L1109 822
                L1109 873 L1072 873z"
        />

        <path
          className="outline"
          d="M1072 970 L1109 970
                L1109 1021 L1072 1021z"
        />
        <path
          className="outline"
          d="M1072 1118 L1109 1118
                L1109 1169 L1072 1169z"
        />
        <path
          className="outline"
          d="M1072 1265 L1109 1265
                L1109 1297 L1072 1297z"
        />
        <path
          className="outline"
          d="M1072 970 L1109 970
                L1109 1021 L1072 1021z"
        />

        {/* Hyllan fence */}
        <path className="outline" d="M1225 750 L1225 920" />
        <path className="outline" d="M1225 1000 L1225 1290" />
        {this.props.children}
      </Group>
    );
  }

  componentDidUpdate(prevProps) {
    // Hide
    if (!prevProps.transparent && this.props.transparent) {
      setTimeout(() => this.setState({ display: false }), 1000);
    } else if (prevProps.transparent && !this.props.transparent) {
      this.setState({ display: true });
    }
  }
}
