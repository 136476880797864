import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  height: calc(100vh - 56px);
  width: 100vw;
  margin: 0;
`;

export const Content = styled.div`
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  // margin-bottom: 56px;
  overflow: auto;
`;
