import styled from "styled-components";
import dancefloor from "assets/images/dancefloors/hyllan.jpg";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const TopContainer = styled.div`
  flex: 0 0 auto;
  padding-bottom: 48px;
  > .tpLogo {
    display: block;
    margin: 36px auto;
    width: 120px;

    @media only screen and (max-width: 960px) {
      margin: 24px auto 6px auto;
    }
  }

  > p {
    display: block;
    margin: 0 auto 12px auto;
    max-width: 320px;
    width: 100%;

    line-height: 160%;
    font-weight: 300;
    text-align: justify;
    font-weight: 0.875rem;
  }
`;

export const BottomContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${dancefloor});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  // box-shadow: inset 0 0 24px rgba(0, 0, 0, 0.5);
`;

export const CountdownContainer = styled.h1`
  font-size: 2rem;
  letter-spacing: 0rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  // font-style: italic;
  line-height: 140%;
  padding-bottom: 0;
  padding-top: 24px;

  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 960px) {
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
  }
`;

export const FacebookButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 48px;
  margin: 48px auto 6px auto;
  padding: 0 24px;
  max-width: 240px;
  border: 4px solid rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  // background-color: rgba(0, 0, 0, 0.5);

  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    color: rgba(0, 0, 0, 0.9);
    text-shadow: none;
  }
`;

export const PeopleCount = styled.div`
  display: block;
  margin: 6px auto 56px auto;

  line-height: 200%;
  font-weight: 300;
  text-align: center;
  font-size: 0.875rem;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
`;
