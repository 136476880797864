import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import PropTypes from "prop-types";

/*import wcUrl from "../../../../assets/svgs/icons/wc.svg"

const STRIPE_SIZE = 50
*/
const Group = styled.g`
  &.disabled {
    opacity: 0.5;
  }
  > pattern > path {
    stroke-width: 18;
    stroke-linecap: square;
    stroke: rgba(255, 0, 0, 0.9);
  }

  > path {
    fill: #f00;
  }
  > circle {
    fill: #fff;
  }
  > text {
    font-size: 1.25rem;
    font-family: "Lato", sans-serif;
    font-weight: 300;
  }

  > g {
    fill: #000;
  }
`;

const SmokingArea = ({ path, uniqueId, disabled, onClick, x, y }) => (
  <Group className={classNames({ disabled })}>
    <circle cx={x} cy={y} r="24"></circle>
    <g transform={`translate(${x - 16}, ${y - 17}) scale(0.08) `}>
      <path
        d="M299.307,206.827h-32.64v0.107c-21.76,0-39.467-20.907-39.467-42.667c0-21.76,17.707-37.333,39.467-37.333v-32
				c-39.36,0-71.467,32-71.467,71.467s32,71.467,71.467,71.467v-0.107h32.64c22.293,0,42.027,15.787,42.027,43.84v27.733h32V274.24
				C373.333,235.84,339.2,206.827,299.307,206.827z"
      />
      <rect x="341.333" y="330.667" width="32" height="64" />
      <rect x="0" y="330.667" width="320" height="64" />
      <path
        d="M359.467,154.24c13.12-12.907,21.333-30.933,21.333-50.773C380.8,64,348.693,32,309.333,32v32
				c21.76,0,39.467,17.707,39.467,39.467c0,21.76-17.707,39.467-39.467,39.467v32c47.893,0,85.333,38.933,85.333,86.827v47.573h32
				v-47.68C426.667,214.187,399.36,173.227,359.467,154.24z"
      />
      <rect x="394.667" y="330.667" width="32" height="64" />
    </g>
  </Group>
);

SmokingArea.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
};

export default SmokingArea;
