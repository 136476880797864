import pubbenImage from "assets/images/bars/bar.jpg";
import nyaMatsalen from "assets/images/dancefloors/nyaMatsalen.jpg";
import groten from "assets/images/dancefloors/groten.jpg";
import tidningsrummet from "assets/images/dancefloors/tidningsrummet.jpg";
import hyllan from "assets/images/dancefloors/hyllan.jpg";

export const locations = [
  {
    id: "nya-matsalen",
    title: "Nya Matsalen",
    genres: "EDM, Pop",
    type: "dancefloor",
    imageUrl: nyaMatsalen,
    opening: 1584212400 + 3600 * 2.5,
    floor: 2,
    takesRequests: false
  },
  {
    id: "gamla-matsalen",
    title: "Gamla Matsalen",
    genres: "Hip Hop, R&B, Latino",
    type: "dancefloor",
    imageUrl: tidningsrummet,
    opening: 1584212400 + 3600 * 2,
    floor: 2,
    takesRequests: false
  },
  {
    id: "hyllan",
    title: "Hyllan",
    genres: "Oldies, Schlager",
    type: "dancefloor",
    imageUrl: hyllan,
    opening: 1584212400,
    floor: 2,
    takesRequests: false
  },
  /*{
    id: "tidningsrummet",
    title: "Tidnings- rummet",
    genres: "Hip Hop, R&B, Latino",
    type: "dancefloor",
    imageUrl: tidningsrummet,
    opening: 1578774600,
    floor: 3,
    takesRequests: false
  },*/
  {
    id: "groten",
    title: "Gröten",
    genres: "Drum & Bass",
    type: "dancefloor",
    imageUrl: groten,
    opening: 1584212400 + 3600 * 3,
    floor: 2,
    takesRequests: false
  },
  {
    id: "bar-puben",
    type: "bar",
    title: "Puben",
    serving: "Beer, Ciders, Drinks",
    imageUrl: pubbenImage,
    floor: 2
  },
  {
    id: "bar-lilla-hyllan",
    type: "bar",
    title: "Lilla Hyllan",
    serving: "Beer, Ciders, Drinks",
    imageUrl: pubbenImage,
    floor: 2
  },
  {
    id: "bar-stora-hyllan",
    type: "bar",
    title: "Stora Hyllan",
    serving: "Beer, Ciders",
    imageUrl: pubbenImage,
    floor: 2
  },
  {
    id: "bar-groten",
    type: "bar",
    title: "Quarnevalen Bar",
    serving: "Beer, Ciders",
    imageUrl: pubbenImage,
    floor: 2
  },
  {
    id: "bar-shotbaren",
    type: "bar",
    title: "Shotbaren",
    serving: "Shots",
    imageUrl: pubbenImage,
    floor: 2
  },
  {
    id: "bar-champagnebaren",
    type: "bar",
    title: "Champagne- baren",
    serving: "Wine, Special Assortments",
    imageUrl: pubbenImage,
    floor: 3
  }
];
