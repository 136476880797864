import React from "react";
import styled from "styled-components";

const Group = styled.g`
  transition: opacity 1s ease-in-out;
  > .floor {
    display: none;
    fill: #000;
  }
  > .closed {
    // fill: #e9e9e9; // rgba(255, 255, 255, 0.);
    fill-rule: evenodd;
    stroke: #fff;
    stroke-width: 8;
    display: none;
  }

  > .outline {
    stroke: #fff;
    stroke-width: 8;
    fill: none;
  }

  > .stairs {
    opacity: 0.5;
    stroke: #fff;
    stroke-width: 3;
    fill: none;
  }
`;
export default class Floor3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      mounted: false
    };
  }

  static getDerivedStateFromProps(props, prevState) {
    if (!prevState.mounted) {
      return { mounted: true, display: !props.transparent };
    }
    return null;
  }

  render() {
    return (
      <Group
        style={{
          visibility: this.state.display ? "inherit" : "hidden",
          opacity: this.props.transparent ? 0 : 1
        }}
      >
        <path
          className="floor"
          d="M1400 550 L 1567 513 L1519 272 
            L913 394 L963 636 L645 700 L645 1288 L538 1625
            L1248 1911 L1320 1733 L1432 1778 L1397 1868
            L1446 1887 L1272 2324 L1341 2350 L1681 1500
            L1401 1442"
        />
        <path
          className="closed"
          d="M538 1625 L1248 1911
                L1105 2255 L1341 2350
                L1681 1500
                L1401 1442 L1400 550 
                L1567 513 L1519 272
                L1041 369 L913 394 L963 636 L645 700 L645 1288
                L538 1625
                M1208 1401 L1205 1414
                L1510 1475 L1478 1650
                L1488 1652 L1520 1478 L1663 1508 L1644 1561
                L1590 1552 L1544 1675
                L1551 1676 L1595 1561 L1639 1570 L1461 2022
                L1380 2228 L1337 2210 L1463 1890
                L1397 1868 
                L1432 1778 L1320 1733 L1251 1898
                L1028 1810 L1098 1631 L1025 1614
                L985 1797 L558 1617 L657 1304 L1072 1388
                L1038 1552 
                L1117 1570 L1154 1390 L660 1291 
                L660 787
                L975 725 L975 640 L925 400 L1028 383
                L1060 523 L1069 522 L1037 381 L1499 282 L1531 429
                L1214 500 L1227 570 L1072 603 L1072 725
                L1109 725 L1109 607 L1229 581 
                L1384 548 L1380 1440z"
        />
        <path
          d={`M1681 1500
                L1401 1442 L1400 550 
                L1567 513 L1519 272
                L1041 369 L913 394 L963 636 L979 721 
                L1072 700 L1072 1373 L1154 1390
                L1103 1643 L1554 1820z`}
          className="outline"
        />

        {/* Wall between tidningsrummet and radiorummet */}
        <path
          className="outline"
          d="M1400 1442 L1225 1405 L1225 1000 C1170 1000, 1170 750, 1250 735 L1400 710"
        />

        {/* 37 180*/}
        <path
          className="outline"
          d="M1515 1464 L1493 1580 L1510 1585 L1550 1470"
        />

        {/* Stairs at gröten */}
        <g className="stairs">
          <path d="M1000 533 L982 443" />
          <path d="M1060 521 l-120 24" />
          <path d="M1058 511 l-120 24" />
          <path d="M1056 501 l-120 24" />
          <path d="M1054 491 l-120 24" />
          <path d="M1052 481 l-120 24" />
          <path d="M1050 471 l-120 24" />
          <path d="M1048 461 l-120 24" />
          <path d="M1046 451 l-120 24" />
          <path d="M1044 441 l-120 24" />
          <path d="M1042 431 l-120 24" />
        </g>

        {/* Quarnevalen kontor and restroom */}
        <path className="outline" d="M1400 550 L1081 616 L1025 370" />
        <path className="outline" d="M1065 539 L1190 512 L1208 590" />

        {/* Wall to the right tidningsrummet */}
        <path className="outline" d="M1590 1542 L1494 1796" />
        <path className="outline" d="M1602 1695 l-120 -50" />
        {/* Stairs to pubben */}

        <g className="stairs">
          <path d="M1542 1670 l60 25" />
          <path d="M1546 1660 l60 25" />
          <path d="M1550 1650 l60 25" />
          <path d="M1554 1640 l60 25" />
          <path d="M1558 1630 l60 25" />
          <path d="M1562 1620 l60 25" />
          <path d="M1566 1610 l60 25" />
          <path d="M1570 1600 l60 25" />

          <path d="M1591 1543 l-60 -25" />
          <path d="M1587 1553 l-60 -25" />
          <path d="M1583 1563 l-60 -25" />
          <path d="M1579 1573 l-60 -25" />
          <path d="M1575 1583 l-60 -25" />
        </g>

        {this.props.children}
      </Group>
    );
  }
  componentDidUpdate(prevProps) {
    // Hide
    if (!prevProps.transparent && this.props.transparent) {
      setTimeout(() => this.setState({ display: false }), 1000);
    } else if (prevProps.transparent && !this.props.transparent) {
      this.setState({ display: true });
    }
  }
}
