import React from "react";
import { NavLink } from "react-router-dom";

import * as Styles from "./BottomNavigation.styles";

import { ReactComponent as HomeIcon } from "assets/svgs/icons/home.svg";
import { ReactComponent as BarIcon } from "assets/svgs/icons/bar.svg";
import { ReactComponent as MusicIcon } from "assets/svgs/icons/music.svg";
import { ReactComponent as MapIcon } from "assets/svgs/icons/map.svg";

export default class BottomNavigation extends React.Component {
  render() {
    return (
      <Styles.Container>
        <NavLink exact={true} to="/" activeClassName="active">
          <HomeIcon />
          <span>Home</span>
        </NavLink>
        <NavLink to="/bar/" activeClassName="active">
          <BarIcon />
          <span>Bar</span>
        </NavLink>
        <NavLink to="/music/" activeClassName="active">
          <MusicIcon />
          <span>Music</span>
        </NavLink>
        <NavLink to="/map/" activeClassName="active">
          <MapIcon />
          <span>Map</span>
        </NavLink>
      </Styles.Container>
    );
  }
}

BottomNavigation.propTypes = {};
