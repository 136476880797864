import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import PropTypes from "prop-types";

const Group = styled.g`
  &.disabled {
    opacity: 0.5;
  }
  > circle {
    fill: #fff;
  }

  > path {
    stroke: #000;
    stroke-width: 4;
    fill: none;
  }
`;

const Stairs = ({ path, uniqueId, disabled, onClick, x, y }) => (
  <Group className={classNames({ disabled })}>
    <circle cx={x} cy={y} r="24"></circle>
    <path
      transform={`translate(-1, 1)`}
      d={`M${x - 10} ${y + 7}
      L${x - 3} ${y + 7} L${x - 3} ${y} L${x + 4} ${y} 
      L${x + 4} ${y - 7} L${x + 11} ${y - 7}`}
    />
  </Group>
);

Stairs.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
};

export default Stairs;
