import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import classNames from "classnames";

const bgKeyframes = keyframes`
  from {
    fill: #999;
  }
  to {
    fill: #fff;
  }
`;
const Group = styled.g`
  > path.active {
    fill: none;
    &.active {
      animation: ${bgKeyframes} 1s infinite alternate;
    }
  }
  > g {
    > text {
      fill: #fff;
      font-family: "Oswald", sans-serif;
      font-weight: 600;
      font-size: 3rem;
      text-transform: uppercase;
      letter-spacing: 100;

      &.active {
        fill: #000;
      }
    }
  }
`;

export const GamlaMatsalen = ({ active = false, onClick }) => (
  <Group onClick={onClick}>
    <path
      className={classNames({ active })}
      d="M981 1802 L541 1622 L648 1291 L1070 1376"
    />
    <g transform="translate(650, 1440) rotate(17.8)">
      <text x="0" y="0" textLength="180" className={classNames({ active })}>
        Gamla
      </text>
      <text x="0" y="80" textLength="280" className={classNames({ active })}>
        Matsalen
      </text>
    </g>
  </Group>
);

GamlaMatsalen.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default GamlaMatsalen;
