import React from "react";
import styled, { keyframes } from "styled-components";
import classNames from "classnames";
import PropTypes from "prop-types";

const bgKeyframes = keyframes`
  from {
    fill: #999;
  }
  to {
    fill: #fff;
  }
`;

const Group = styled.g`
  > path {
    fill: transparent;
    &.active {
      animation: ${bgKeyframes} 1s infinite alternate;
    }
  }
  > g {
    > text {
      fill: #fff;
      font-family: "Oswald", sans-serif;
      font-weight: 600;
      font-size: 3rem;
      text-transform: uppercase;
      letter-spacing: 100;

      &.active {
        fill: #000;
      }
    }
  }
`;

export const Groten = ({ onClick, active = true }) => (
  <Group onClick={onClick}>
    <path
      className={classNames({ active })}
      d="M1028 374 L1400 299 l37 154
L1212 497 L1229 581 L1084 611"
    />

    <g
      transform="translate(1115, 460) rotate(-12.2)" /* transform="translate(1330, 410) rotate(-12.2)" */
    >
      <text x="0" y="0" textLength="180" className={classNames({ active })}>
        Gröten
      </text>
    </g>
  </Group>
);

Groten.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool
};

export default Groten;
