import React from "react";

import * as Styles from "./MenuView.styles";
import BottomNavigation from "../BottomNavigation/BottomNavigation";

class MenuView extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Styles.Container>
        <Styles.Content>{this.props.children}</Styles.Content>
        <BottomNavigation />
      </Styles.Container>
    );
  }
}

MenuView.propTypes = {};

export default MenuView;
